<template>
  <f7-navbar>
    <f7-nav-left>
      <f7-link v-if="type === 'back'" @click.stop="$h.goBack()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
      <f7-link v-else-if="type === 'order'" @click.stop="$h.goBack()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
      <f7-link v-else-if="type === 'callback'" @click.stop="navigateProfile()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
      <f7-link v-else-if="type === 'mini'" @click.stop="navigateProfile()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>

      <LeftPanelNavigationComponent v-else />
    </f7-nav-left>

    <f7-nav-title> {{ title }} </f7-nav-title>

    <f7-nav-right>
      <div class="conc-btn" @click="chatConcierge">
        <div class="con-text-box" style="cursor: pointer">
          <img :src="require('@/assets/images/con-ser-icon.png')" style="width: 45px; position: relative; left: 4px" loading="lazy" />
          <div class="con-text-holder">
            <p>{{ $t.getTranslation("LBL_CONCIERGE_TEXT") }}</p>
            <p style="color: #0c4d8f">{{ $t.getTranslation("LBL_SERVICE_TEXT") }}</p>
          </div>
        </div>
        <div class="conc-text">{{ $t.getTranslation("LBL_CONCIERGE_POPUP_TEXT") }}</div>
      </div>

      <f7-link v-if="invoice && orderKey" @click="download">
        <font-awesome-icon :icon="['far', 'file-download']" fixed-width /> <span style="font-size: 14px; margin-left: 10px">{{ $t.getTranslation("BTN_DOWNLOAD_INVOICE", "Invoice") }}</span>
      </f7-link>

      <!-- <ChatNavigationComponent v-if="!hidechat" /> -->

      <f7-link v-if="search === true" class="searchbar-enable" :data-searchbar="'.' + searchname">
        <font-awesome-icon :icon="['far', 'search']" fixed-width />
      </f7-link>

      <f7-link v-if="cart === true && $h.canAddToCart()" href="/shop/cart/">
        <font-awesome-icon :icon="['far', 'shopping-cart']" fixed-width />
      </f7-link>
    </f7-nav-right>

    <f7-searchbar v-if="search" v-model:value="searchValue" :class="searchname" expandable :placeholder="$t.getTranslation('LBL_SEARCH')" :clear-button="true" :custom-search="true" :backdrop="false" @keyup.enter="onSearch"></f7-searchbar>
  </f7-navbar>
</template>

<script>
import { defineComponent, computed, ref, watch, inject, defineAsyncComponent, onMounted } from "vue";
import { useStore } from "@/store";
import { configs } from "@/utils/configs.js";

import { f7 } from "framework7-vue";

// import ChatNavigationComponent from "@/components/chat/ChatNavigationComponent.vue";
//import LeftPanelNavigationComponent from '@/components/navigations/LeftPanelNavigationComponent.vue'

export default defineComponent({
  name: "ProductNavigationComponent",
  components: {
    LeftPanelNavigationComponent: defineAsyncComponent(() => import(/* webpackChunkName: "left-panel" */ /* webpackMode: "lazy" */ "@/components/navigations/LeftPanelNavigationComponent.vue")),
  },
  props: {
    invoice: { type: Boolean, default: false },
    hidechat: { type: Boolean, default: false },
    title: String,
    type: String,
    searchname: String,
    f7router: Object,
    f7route: Object,
    search: { type: Boolean, default: true },
    cart: { type: Boolean, default: true },
    parentOrderKey: { type: String, default: null },
    orderKey: { type: String, default: null },
  },
  emits: ["searched"],
  setup(props, { emit }) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;

    const activeLink = computed(() => store.getters["page/getActiveLink"]);

    const searchValue = ref("");
    let searchTimeout = false;

    onMounted(() => {
      if (props?.f7route?.query?.search) searchValue.value = props?.f7route?.query?.search;
    });

    watch(searchValue, (newValue, oldValue) => {
      if (searchTimeout) clearTimeout(searchTimeout);

      searchTimeout = setTimeout(function () {
        if (newValue == null) {
          return true;
        }

        onSearch();
      }, 3000);
    });

    const download = () => {
      window.location.href = `${configs.baseURL}/order/invoice?key=${props.orderKey}`;
    };

    const onSearch = () => {
      if (searchTimeout) clearTimeout(searchTimeout);
      emit("searched", searchValue.value);
    };

    const back = () => {
      if (props?.parentOrderKey) {
        window.location.href = "/profile/order/sub/?ParentOrderKey=" + props.parentOrderKey;
      } else {
        window.location.href = "/profile/order/?mode=all";
      }
    };

    let getServiceImage = (lang) => {
      return lang == "EN" ? require("@/assets/images/service_en.png") : require("@/assets/images/service_ch.png");
    };

    const chatConcierge = () => {
      //add store dispatch here..
      store.dispatch("user/setData", {
        IsConcierge: 1,
      });
      $f7router.navigate({ name: "chat" });
    };

    const navigateProfile = () => {
      $f7router.navigate({ name: "profilePage" });
    };

    return {
      navigateProfile,
      download,
      activeLink,
      searchValue,
      onSearch,
      back,
      getServiceImage,
      chatConcierge,
      store,
    };
  },
});
</script>

<style scoped>
.conc-btn {
  position: relative;
  display: inline-block;
}

.conc-btn .conc-text {
  visibility: hidden;
  width: 160px;
  background-color: #1a64ca;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 9999;
  top: 5px;
  right: 55px;
  font-size: 10px;
}

.conc-btn .conc-text::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #1a64ca;
}

.conc-btn:hover .conc-text {
  visibility: visible;
}
.con-text-box {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.con-text-holder {
  font-size: 10px;
  text-align: center;
  line-height: 13px;
  font-weight: 700;
}
.con-text-holder p {
  margin: 0px;
}
</style>
